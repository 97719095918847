import { createAsyncThunk } from '@reduxjs/toolkit';
import { getStories, getStoryById, getStoryTreeById } from '../../services/stories';

export const fetchStories = createAsyncThunk('stories/fetchStories', async (queryConfig, thunkApi) => {
  const { rows } = await getStories(queryConfig);
  return rows;
});

export const fetchStoryById = createAsyncThunk('stories/fetchStoryById', async (id, thunkApi) => {
  const res = await getStoryById(id);
  return res;
});

export const fetchStoryTreeById = createAsyncThunk('stories/fetchStoryTreeById', async (query, thunkApi) => {
  const {
    stories: { currentStory }
  } = thunkApi.getState();
  const res = await getStoryTreeById(query?.id || currentStory.id);
  return { data: res, hasPageInParams: query?.hasPageInParams || false };
});
