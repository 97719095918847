import { createSlice } from '@reduxjs/toolkit';

import { fetchStories, fetchStoryById, fetchStoryTreeById } from '../thunks/stories';

export const storiesSlice = createSlice({
  name: 'stories',
  initialState: {
    stories: [],
    currentStory: null,
    currentStoryChapters: null,
    isStoriesFetching: false,
    isStoryFetching: false,
    currentLang: 'nb',
    progress: null
  },
  reducers: {
    setStories: (state, action) => {
      state.stories = action.payload;
    },
    setCurrentStory: (state, action) => {
      state.currentStory = action.payload;
    },
    setStoriesFetchingStatus: (state, action) => {
      state.isStoriesFetching = action.payload;
    },
    setStoryFetchingStatus: (state, action) => {
      state.isStoryFetching = action.payload;
    },
    setCurrentLanguage: (state, action) => {
      state.currentLang = action.payload;
    },
    setProgress: (state, action) => {
      state.progress = action.payload;
    }
  },
  extraReducers: {
    [fetchStories.pending]: state => {
      state.isStoriesFetching = true;
    },
    [fetchStories.fulfilled]: (state, action) => {
      state.stories = action.payload;
      state.isStoriesFetching = false;
    },
    [fetchStoryById.pending]: state => {
      state.isStoryFetching = true;
    },
    [fetchStoryById.fulfilled]: (state, action) => {
      state.stories = [...state.stories.filter(s => s.id !== action.payload.id), action.payload];
      state.currentStory = action.payload;
      state.isStoryFetching = false;
    },
    [fetchStoryTreeById.pending]: state => {
      state.isStoryFetching = true;
    },
    [fetchStoryTreeById.fulfilled]: (state, action) => {
      let filteredChapters = action.payload?.data?.filter(treeItem => treeItem?.type === 'chapter');
      state.currentStoryChapters = filteredChapters?.map(chapter => {
        return { ...chapter, children: chapter.children?.map(page => page.id) };
      });
      state.isStoryFetching = false;
    }
  }
});

export const {
  setStoriesFetchingStatus,
  setStoryFetchingStatus,
  setStories,
  setCurrentStory,
  setCurrentLanguage,
  setProgress
} = storiesSlice.actions;

export default storiesSlice.reducer;
